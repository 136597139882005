<template>
  <header class="shadow-sm bg-white">
    <nav class="container mx-auto p-4 flex justify-between text-4xl">
      <NuxtLink to="/" class="font-bold"
        >11 Hero <span class="text-sm">(pre-alpha)</span></NuxtLink
      >
      <ul class="flex gap-4">
        <li>
          <NuxtLink to="/" class="btn">Home</NuxtLink>
        </li>
        <DevOnly>
          <li>
            <NuxtLink to="/about" class="btn">About</NuxtLink>
          </li>
        </DevOnly>
        <li>
          <NuxtLink to="/files" class="btn">Files</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/matches" class="btn">Matches</NuxtLink>
        </li>
        <li>
          <NuxtLink to="https://solidslime.net/cloud" class="btn"
            >ETT Cloud Viewer</NuxtLink
          >
        </li>
        <li>
          <NuxtLink to="/upload" class="btn">Upload</NuxtLink>
        </li>
        <DevOnly>
          <li>
            <NuxtLink to="/sandbox" class="btn">Sandbox</NuxtLink>
          </li>
          <li v-if="user === null">
            <NuxtLink to="/register" class="btn">Register</NuxtLink>
          </li>
          <li v-if="user === null">
            <NuxtLink to="/login" class="btn">Login</NuxtLink>
          </li>
          <li v-if="user">
            <NuxtLink to="/profile" class="btn">Profile</NuxtLink>
          </li>
        </DevOnly>
      </ul>
    </nav>
  </header>
</template>

<script setup>
const user = useSupabaseUser();
</script>

<style scoped></style>
